import { connect } from "react-redux"
import { ThunkDispatch } from "redux-thunk"
import { RootRedux } from "redux/store"
import { styled } from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import TopBar from "component/topbar";
import NavBar from "component/navbar";
import operatorMenuItems from "./operatormenu.items";
import { FAILURE_STATUS, IDLE_STATUS, LOADING_STATUS, StateStatus, SUCCESS_STATUS } from "redux/statestatus";
import { UserType } from "type/user.type";
import { logout } from "redux/authentication/authentication.action";
import { rootInitUser } from "redux/root/root.action";
import MenuItem from "component/menuitem";

interface OwnProps {
}

interface DispatchProps {
  rootInitUser: () => void;
  logout: () => void;
}

interface StateProps {
  rootStateStatus: StateStatus;
  rootUserType: UserType | null;
  rootUserAddons: String[];
  rootErrorMessage: string | null;
}

type Props = StateProps & OwnProps & DispatchProps

const Root = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: "flex",
  height: "100%",
  overflow: "hidden",
  width: "100%"
}));

const Wrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
  paddingTop: 64,
  [theme.breakpoints.up("lg")]: {
    paddingLeft: 256
  }
}));

const ContentContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden"
}));

const Content = styled("div")(({ theme }) => ({
  flex: "1 1 auto",
  height: "100%",
  overflow: "auto"
}));

let addonItems: MenuItem[] = [];

const OperatorLayout = (props: Props) => {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    switch (props.rootStateStatus) {
      case IDLE_STATUS: {
        props.rootInitUser();
        break;
      }
      case LOADING_STATUS: {
        break;
      }
      case SUCCESS_STATUS: {
        switch (props.rootUserType) {
          case UserType.ADMINISTRATOR: {
            navigate('/administrator', { replace: true });
            break;
          }
          case UserType.GUARD: {
            navigate('/guard', { replace: true });
            break;
          }
          case UserType.KIOSK: {
            navigate('/kiosk', { replace: true });
            break;
          }
          case UserType.OPERATOR: {
            break;
          }
          default: {
            props.logout();
            break;
          }
        }

        for(let s in props.rootUserAddons) {
          if (s = "PARKOR_RESIDENT") {
            if (!addonItems.find(ai => ai.id = s)) {
              addonItems.push({id: s, href: "/operator/resident", icon: "apartment", title: "All Parkering Resident"})
            }
          }
        }

        break;
      }
      case FAILURE_STATUS: {
        break;
      }
    }
  }, [props.rootStateStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Root>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen} menuItems={operatorMenuItems} addonItems={addonItems} />
      <Wrapper>
        <ContentContainer>
          <Content>
            <Outlet />
          </Content>
        </ContentContainer>
      </Wrapper>
    </Root>
  )
}

const mapStateToProps = (redux: RootRedux): StateProps => {
  return {
    rootStateStatus: redux.root.status,
    rootUserType: redux.root.userType,
    rootUserAddons: redux.root.addons,
    rootErrorMessage: redux.root.errorMessage
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => {
  return {
    rootInitUser: async () => {
      await dispatch(rootInitUser())
    },
    logout: async () => {
      await dispatch(logout())
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OperatorLayout);